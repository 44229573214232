export default {
  items: [
    {
      title: true,
      name: 'Absolut WebGIS',
      wrapper: {
        element: '',
        attributes: {},
      },
    },
    {
      name: 'Dashboard',
      url: '/app/dashboard',
      icon: 'icon-pie-chart',
    },
    {
      name: 'GIS',
      url: '/app/gis',
      icon: 'icon-map',
    },
    {
      name: 'Vendas',
      url: '/app/vendas',
      icon: 'icon-basket-loaded',
    },
    {
      name: 'Estoque',
      url: '/app/estoque',
      icon: 'icon-social-dropbox',
    },
    {
      name: 'Romaneio Abatidas',
      url: '/app/extracao',
      icon: 'icon-note',
    },
    {
      name: 'Esplanadão',
      url: '/app/esplanadao',
      icon: 'icon-action-redo',
    },
    {
      name: 'Relatórios',
      url: '/app/relatorios',
      icon: 'icon-cloud-download',
    },
  ],
}
