import { takeLatest, put, all } from 'redux-saga/effects'
import { Creators as loader } from '../ducks/loader'
// import { Types as sessionTypes } from '../ducks/session'
import { Types as loginTypes } from '../ducks/login'
import { Types as extracaoTypes } from '../ducks/extracao'
import { Types as estoqueTypes } from '../ducks/estoque'
import { Types as relatoriosTypes } from '../ducks/relatorios'
import { Types as qrcodeTypes } from '../ducks/qrcode'
import { Types as vendasTypes } from '../ducks/vendas'
import { Types as esplanadaTypes } from '../ducks/esplanada'

const loginUser = {
  object: 'login',
  method: 'loginUser',
}

const relatoriosDownload = {
  object: 'relatorios',
  method: 'relatoriosDownload',
}

const getDashboard = {
  object: 'dashboard',
  method: 'getDashboard',
}

const getTree = {
  object: 'qrcode',
  method: 'getTree',
}

const getCompanies = {
  object: 'login',
  method: 'getCompanies',
}

const getExtracao = {
  object: 'extracao',
  method: 'getExtracao',
}

const getEstoque = {
  object: 'estoque',
  method: 'getEstoque',
}

const getVendas = {
  object: 'vendas',
  method: 'getVendas',
}

const getEsplanada = {
  object: 'esplanada',
  method: 'getEsplanada',
}

const getParams = (data) => ({
  object: data.split('_')[2].toLowerCase(),
  method: data.split('_')[1].toLowerCase(),
})

function* addLoader(params) {
  const { type } = params
  const result = type === undefined ? params : getParams(type)
  yield put(loader.startLoading(result))
}

function* removeLoader(params, full = false) {
  if (!full) {
    const { type } = params
    const result = type === undefined ? params : getParams(type)
    yield put(loader.stopLoading(result))
  } else {
    yield put(loader.stopLoadingFull(params.object))
  }
}

function* SagaLoader() {
  yield all([
    takeLatest(loginTypes.START_LOGIN, addLoader, loginUser),
    takeLatest(loginTypes.ERROR_LOGIN, removeLoader, loginUser),
    takeLatest(
      loginTypes.ERROR_GET_COMPANIES,
      removeLoader,
      getCompanies,
      true
    ),
    takeLatest(
      loginTypes.SUCCESS_GET_COMPANIES,
      removeLoader,
      getCompanies,
      true
    ),
    takeLatest(esplanadaTypes.START_GET_ESPLANADA, addLoader, getEsplanada),
    takeLatest(esplanadaTypes.ERROR_GET_ESPLANADA, removeLoader, getEsplanada),
    takeLatest(
      esplanadaTypes.SUCCESS_GET_ESPLANADA,
      removeLoader,
      getEsplanada
    ),
    takeLatest(vendasTypes.START_GET_VENDAS, addLoader, getVendas),
    takeLatest(vendasTypes.ERROR_GET_VENDAS, removeLoader, getVendas),
    takeLatest(vendasTypes.SUCCESS_GET_VENDAS, removeLoader, getVendas),
    takeLatest(qrcodeTypes.START_GET_TREE, addLoader, getTree),
    takeLatest(qrcodeTypes.ERROR_GET_TREE, removeLoader, getTree),
    takeLatest(qrcodeTypes.SUCCESS_GET_TREE, removeLoader, getTree),
    takeLatest(estoqueTypes.START_GET_ESTOQUE, addLoader, getEstoque),
    takeLatest(estoqueTypes.ERROR_GET_ESTOQUE, removeLoader, getEstoque),
    takeLatest(estoqueTypes.SUCCESS_GET_ESTOQUE, removeLoader, getEstoque),
    takeLatest(extracaoTypes.START_GET_EXTRACAO, addLoader, getExtracao),
    takeLatest(extracaoTypes.ERROR_GET_EXTRACAO, removeLoader, getExtracao),
    takeLatest(extracaoTypes.SUCCESS_GET_EXTRACAO, removeLoader, getExtracao),
    takeLatest(loginTypes.START_GET_FILES, addLoader, getDashboard),
    takeLatest(loginTypes.ERROR_GET_FILES, removeLoader, getDashboard),
    takeLatest(loginTypes.ERROR_GET_FILES_FROM_AWS, removeLoader, getDashboard),
    takeLatest(
      loginTypes.SUCCESS_GET_FILES_FROM_AWS,
      removeLoader,
      getDashboard
    ),
    takeLatest(
      relatoriosTypes.START_GET_RELATORIOS,
      addLoader,
      relatoriosDownload
    ),
    takeLatest(
      relatoriosTypes.ERROR_GET_RELATORIOS,
      removeLoader,
      relatoriosDownload
    ),
    takeLatest(
      relatoriosTypes.SUCCESS_GET_RELATORIOS,
      removeLoader,
      relatoriosDownload
    ),
  ])
}

export default SagaLoader
