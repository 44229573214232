/* eslint-disable max-classes-per-file */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap'
import axios from 'axios'
import envURLs from '../../../envURLs'
import LoadingOverlay from 'react-loading-overlay'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  toggleLoginState(active) {
    this.setState({ loading: active })
  }

  render() {
    return (
      <div className='app flex-row align-items-center'>
        <LoadingOverlay
          active={this.state.loading}
          spinner
          text='Logando o usuário...'
        />
        <Container>
          <Row className='justify-content-center'>
            <Col md='8'>
              <CardGroup>
                <Card className='p-4'>
                  <CardBody>
                    <HandleLogin
                      toggleLoginState={(active) =>
                        this.toggleLoginState(active)
                      }
                    />
                  </CardBody>
                </Card>
                <Card
                  className='text-white py-5 d-md-down-none'
                  style={{
                    width: '44%',
                    backgroundColor: 'orange',
                    opacity: '0.75',
                  }}
                />
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

class HandleLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loginRequestError: '',
      isEmpresaValid: '',
      isPasswordValid: '',
      isUsernameValid: '',
      toDashBoard: false,
    }

    this.handleLogin = this.handleLogin.bind(this)
    this.resetValidations = this.resetValidations.bind(this)
    this.goToDashboard = this.goToDashboard.bind(this)
  }

  resetValidations() {
    this.setState({
      isEmpresaValid: '',
      isPasswordValid: '',
      isUsernameValid: '',
      loginRequestError: '',
    })
  }

  goToDashboard() {
    if (this.state.toDashboard === true) {
      return <Redirect to='/admin/dashboard' />
    }
  }

  handleLogin(e) {
    e.preventDefault()
    let username
    let password
    if (e.target.elements.username.value) {
      username = e.target.elements.username.value
    } else {
      this.setState({ isUsernameValid: 'O usuário é um campo obrigatório' })
      return
    }
    if (e.target.elements.password.value) {
      password = e.target.elements.password.value
    } else {
      this.setState({ isPasswordValid: 'A senha é um campo obrigatório' })
      return
    }

    const payload = {
      username,
      password,
    }
    if (
      process.env.REACT_APP_ENV_PASSWORD === payload.password &&
      process.env.REACT_APP_ENV_USER === payload.username
    ) {
      const url = `${envURLs}/auth`
      const payloadAdmin = {
        username: process.env.REACT_APP_ADMIN_USER,
        password: process.env.REACT_APP_ADMIN_PASSWORD,
        contract: process.env.REACT_APP_ADMIN_CONTRACT,
      }
      this.props.toggleLoginState(true)
      axios({
        method: 'post',
        url,
        data: payloadAdmin,
      })
        .then(async (response) => {
          if (!!response.status && response.status === 200) {
            if (!response.data.success) {
              this.setState({
                loginRequestError: response.data.message,
                isPasswordValid: '',
                isUsernameValid: '',
              })
              return
            }
            localStorage.setItem('loginToken', response.data.token)
            this.resetValidations()
            this.setState({
              toDashboard: true,
            })
          }
          this.props.toggleLoginState(false)
        })
        .catch((error) => {
          // erro 401 usuário não autorizado

          this.setState({
            loginRequestError: error.message ? error.message : 'Unknown error',
            isPasswordValid: '',
            isUsernameValid: '',
          })
          this.props.toggleLoginState(false)
        })
    } else {
      this.setState({
        loginRequestError: 'Usuário, senha ou id da empresa incorretos',
        isEmpresaValid: '',
        isPasswordValid: '',
        isUsernameValid: '',
      })
    }
  }

  render() {
    return (
      <div>
        {this.goToDashboard()}

        <Form onSubmit={this.handleLogin}>
          <h1>Login</h1>
          <p className='text-muted'>WebGIS Admin</p>
          <InputGroup className='mb-3'>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>
                <i className='icon-user' />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type='text'
              name='username'
              placeholder='Usuário'
              autoComplete='username'
            />
          </InputGroup>
          <p className='text-danger'>{this.state.isUsernameValid}</p>
          <InputGroup className='mb-3'>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>
                <i className='icon-lock' />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type='password'
              name='password'
              placeholder='Senha'
              autoComplete='current-password'
            />
          </InputGroup>
          <p className='text-danger'>{this.state.isPasswordValid}</p>
          <p className='text-danger'>{this.state.loginRequestError}</p>
          <Row>
            <Col xs='6'>
              <Button type='submit' className='px-4'>
                Login
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

export default Login
