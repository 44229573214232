import { createReducer, createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
  successGetEstoque: ['data'],
  errorGetEstoque: ['error'],
  clearEstoque: [''],
  startGetEstoque: ['data'],
})

const initialState = {
  error: '',
  estoque: [],
}

function clearEstoque() {
  return initialState
}

function successGetEstoque(state, { data }) {
  return {
    ...state,
    estoque: data,
  }
}

function errorGetEstoque(state, { error }) {
  return {
    ...state,
    error,
  }
}

const estoque = {
  [Types.SUCCESS_GET_ESTOQUE]: successGetEstoque,
  [Types.ERROR_GET_ESTOQUE]: errorGetEstoque,
  [Types.CLEAR_ESTOQUE]: clearEstoque,
}

export default createReducer(initialState, estoque)
