import { takeLeading, put, call } from 'redux-saga/effects'
import { getButtonVendas as getButtonVendasRequest } from '../../services/buttons'
import {
  Creators as buttonCreators,
  Types as buttonTypes,
} from '../ducks/buttons'

import { Creators as notifyCreators } from '../ducks/notify'

function* GetButtonVendas({ data }) {
  try {
    const response = yield call(getButtonVendasRequest, data)
    const button = response.data.data
    if (!button.message) {
      yield put(buttonCreators.successGetButtonVendas(button))
    } else {
      yield put(buttonCreators.errorGetButtonVendas(button))
    }
  } catch (error) {
    yield put(buttonCreators.errorGetButtonVendas(error))
    yield put(
      notifyCreators.error(
        `Não foi possível encontrar dados de vendas para o projeto`
      )
    )
  }
}

function* sagaButtonVendas() {
  yield takeLeading(buttonTypes.START_GET_BUTTON_VENDAS, GetButtonVendas)
}

export default sagaButtonVendas
