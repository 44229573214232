import React from 'react'

const AdminDashboard = React.lazy(() => import('./views/Dashboard/Dashboard'))
const AdminContract = React.lazy(() => import('./views/Contract/Contract'))
const AdminProject = React.lazy(() => import('./views/Project/Project'))
const AdminFile = React.lazy(() => import('./views/File/File'))

// change also _nav.js
const routes = [
  { path: '/admin', exact: true, name: 'Admin Home' },
  {
    path: '/admin/dashboard',
    name: 'Admin Dashboard',
    component: AdminDashboard,
  },
  { path: '/admin/contract', name: 'Admin Contract', component: AdminContract },
  { path: '/admin/project', name: 'Admin Project', component: AdminProject },
  { path: '/admin/file', name: 'Admin File', component: AdminFile },
  // { path: '/outro-link', exact: true, name: 'Outro', component: CadastroNota },
]
export default routes
