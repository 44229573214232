import { takeLeading, put, call } from 'redux-saga/effects'
import { getCortadaMato as getCortadaMatoRequest } from '../../services/buttons'
import {
  Creators as buttonsCreators,
  Types as buttonsTypes,
} from '../ducks/buttons'
import { Creators as notifyCreators } from '../ducks/notify'

function* GetCortadaMato({ data }) {
  try {
    const response = yield call(getCortadaMatoRequest, data)
    const cortadaMato = response.data.data
    yield put(buttonsCreators.successGetCortadaMato(cortadaMato))
  } catch (error) {
    yield put(buttonsCreators.errorGetCortadaMato(error))
    yield put(
      notifyCreators.error(
        `Não foi possível encontrar dados de cortadaMato para o projeto`
      )
    )
  }
}

function* sagaCortadaMato() {
  yield takeLeading(buttonsTypes.START_GET_CORTADA_MATO, GetCortadaMato)
}

export default sagaCortadaMato
