import { takeLeading, put, call } from 'redux-saga/effects'
import { getQRCode as getQRCodeRequest } from '../../services/qrcode'
import {
  Creators as qrcodeCreators,
  Types as qrcodeTypes,
} from '../ducks/qrcode'
import { Creators as notifyCreators } from '../ducks/notify'

function* GetTree({ data, onSuccess }) {
  try {
    const response = yield call(getQRCodeRequest, data)
    const qrcode = response.data
    yield put(qrcodeCreators.successGetTree(qrcode))
    onSuccess(qrcode)
  } catch (error) {
    yield put(qrcodeCreators.errorGetTree(error))
    yield put(
      notifyCreators.error(
        `Não foi possível encontrar dados de qrcode para esta árvore`
      )
    )
  }
}

function* sagaRelatorios() {
  yield takeLeading(qrcodeTypes.START_GET_TREE, GetTree)
}

export default sagaRelatorios
