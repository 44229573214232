import { api } from './api'

export const getAbatidas = ({ token, project, contract: { number } }) =>
  api(token).get(`/project/${project}&${number}/abatidas`)

export const getAutex = ({ token, project, contract: { number } }) =>
  api(token).get(`/project/${project}&${number}/autex`)

export const getCortadaMato = ({ token, project, contract: { number } }) =>
  api(token).get(`/project/${project}&${number}/cortada-mato`)

export const getPatioArraste = ({ token, project, contract: { number } }) =>
  api(token).get(`/project/${project}&${number}/patio-arraste`)

export const getButtonVendas = ({ token, project, contract: { number } }) =>
  api(token).get(`/project/${project}&${number}/buttonVendas`)

export const getButtonEstoque = ({ token, project, contract: { number } }) =>
  api(token).get(`/project/${project}&${number}/buttonEstoque`)
