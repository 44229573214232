/* eslint-disable import/no-extraneous-dependencies */
// reducers.js
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import login from '../store/ducks/login'
import esplanada from '../store/ducks/esplanada'
import extracao from '../store/ducks/extracao'
import estoque from '../store/ducks/estoque'
import vendas from '../store/ducks/vendas'
import buttons from '../store/ducks/buttons'
import notify from '../store/ducks/notify'
import gis from '../store/ducks/gis'
import relatorios from '../store/ducks/relatorios'
import loader from '../store/ducks/loader'
import qrcode from '../store/ducks/qrcode'

const createRootReducer = (history) =>
  combineReducers({
    qrcode,
    buttons,
    login,
    relatorios,
    notify,
    extracao,
    estoque,
    vendas,
    esplanada,
    gis,
    loader,
    router: connectRouter(history),
  })
export default createRootReducer
