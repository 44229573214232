import { createReducer, createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
  successGetRelatorios: ['data'],
  errorGetRelatorios: ['error'],
  clearRelatorios: [''],
  startGetRelatorios: ['data', 'onSuccess'],
})

const initialState = {
  error: '',
  relatorios: [],
}

function clearRelatorios() {
  return initialState
}

function successGetRelatorios(state, { data }) {
  return {
    ...state,
    relatorios: data,
  }
}

function errorGetRelatorios(state, { error }) {
  return {
    ...state,
    error,
  }
}

const relatorios = {
  [Types.SUCCESS_GET_RELATORIOS]: successGetRelatorios,
  [Types.ERROR_GET_RELATORIOS]: errorGetRelatorios,
  [Types.CLEAR_RELATORIOS]: clearRelatorios,
}

export default createReducer(initialState, relatorios)
