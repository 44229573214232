/* eslint-disable react/no-array-index-key */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React, { Component, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import * as router from 'react-router-dom'
import { Container } from 'reactstrap'
import {
  AppFooter,
  AppHeader,
  AppBreadcrumb2 as AppBreadcrumb,
  AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react'
import BreadCrumbAdmin from './BreadCrumbAdmin'

// routes config
import routes from '../../routesAdmin'

const DefaultFooter = React.lazy(() => import('./DefaultFooter'))
const DefaultHeader = React.lazy(() => import('./DefaultHeader'))

class DefaultLayout extends Component {
  loading = () => (
    <div className='animated fadeIn pt-1 text-center'>Loading...</div>
  )

  signOut(e) {
    e.preventDefault()
    localStorage.removeItem('loginToken')
    this.props.history.push('/admin/login')
  }

  goToSettings(e) {
    e.preventDefault()
    this.props.history.push('/admin/settings')
  }

  render() {
    return (
      <div className='app'>
        <AppHeader fixed>
          <Suspense fallback={this.loading()}>
            <DefaultHeader
              goToSettings={(e) => this.goToSettings(e)}
              onLogout={(e) => this.signOut(e)}
            />
          </Suspense>
        </AppHeader>
        <div className='app-body'>
          {/* <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
            <AppSidebarNav navConfig={navigation} {...this.props} router={router}/>
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar> */}
          <main className='main'>
            {/* <BreadCrumbAdmin appRoutes={routes} router={router} /> */}

            <AppBreadcrumb appRoutes={routes} router={router} />
            <Container fluid>
              <Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) =>
                    route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => <route.component {...props} />}
                      />
                    ) : null
                  )}
                  <Redirect from='/admin' to='/admin/dashboard' />
                </Switch>
              </Suspense>
            </Container>
          </main>
        </div>
        <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    )
  }
}

export default DefaultLayout
