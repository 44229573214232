/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Modal,
  ModalBody,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import LoadingOverlay from 'react-loading-overlay'
import { Creators as loginCreators } from '../../store/ducks/login'
import { Creators as notifyCreators } from '../../store/ducks/notify'

const Login = ({ toasty: Toast }) => {
  const { login } = useSelector((state) => state.loader)
  const dispatch = useDispatch()
  const {
    user,
    project: { selected },
  } = useSelector((state) => state.login)

  useEffect(() => {
    if (!!user.token && !!selected.id) {
      dispatch(push('/app/dashboard'))
    }
  }, [selected.id])

  return (
    <div className='app flex-row align-items-center'>
      <Toast />
      <LoadingOverlay
        active={!!login.getCompanies || !!login.loginUser}
        spinner
      />
      <Container>
        <Row className='justify-content-center'>
          <Col md='8'>
            <CardGroup>
              <Card className='p-4'>
                <CardBody>
                  <HandleLogin />
                </CardBody>
              </Card>
              <Card
                className='text-white py-5 d-md-down-none'
                style={{
                  width: '44%',
                  backgroundColor: 'none',
                  opacity: '0.75',
                }}
              >
                <img
                  alt='Absolut Sistemas Logo'
                  style={{ width: 'fit-content', margin: 'auto' }}
                  src='../../../assets/img/logo.png'
                />
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const HandleLogin = () => {
  const dispatch = useDispatch()
  const { user, company } = useSelector((state) => state.login)

  const handleLogin = (e) => {
    e.preventDefault()
    const { username, contract, password } = e.target.elements

    if (!username.value || !password.value || !contract.value) {
      return dispatch(
        notifyCreators.error(
          'O usuário, a senha e o contrato são campos obrigatórios'
        )
      )
    }

    const payload = {
      username: username.value,
      password: password.value,
      contract: contract.value,
    }

    return dispatch(loginCreators.startLogin(payload))
  }

  const onCompanyClick = (e) => {
    const selectedCompany = e.currentTarget.innerHTML

    company.data.forEach((companyItem) => {
      if (companyItem.name === selectedCompany) {
        const companyPayload = {
          name: companyItem.name,
          id: companyItem._id,
        }
        dispatch(loginCreators.setCompany(companyPayload))
        dispatch(
          loginCreators.startGetProjects(
            {
              company: companyItem._id,
              token: user.token,
            },
            (data) => {
              dispatch(loginCreators.setProject(data))
            }
          )
        )
      }
    })
  }

  return (
    <div>
      <Modal isOpen={!!company.data && !!company.data.length}>
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <h4>Selecione a empresa</h4>{' '}
          <Button
            style={{ float: 'right', width: 'fit-content' }}
            onClick={() => dispatch(loginCreators.clearUserData())}
            color='secondary'
            size='lg'
            block
          >
            Fechar
          </Button>
        </div>
        <ModalBody>
          <ListGroup>
            {company.data ? (
              company.data.map((each) => (
                <ListGroupItem key={each._id}>
                  <Button
                    onClick={(e) => onCompanyClick(e)}
                    color='secondary'
                    size='lg'
                    block
                  >
                    {each.name}
                  </Button>
                </ListGroupItem>
              ))
            ) : (
              <div />
            )}
          </ListGroup>
        </ModalBody>
      </Modal>
      <Form onSubmit={handleLogin}>
        <h1>Login</h1>
        <p className='text-muted'>Entre na sua conta WebGIS</p>
        <InputGroup className='mb-3'>
          <InputGroupAddon addonType='prepend'>
            <InputGroupText>
              <i className='icon-user' />
            </InputGroupText>
          </InputGroupAddon>
          <Input type='text' name='username' placeholder='Usuário' />
        </InputGroup>
        <InputGroup className='mb-3'>
          <InputGroupAddon addonType='prepend'>
            <InputGroupText>
              <i className='icon-lock' />
            </InputGroupText>
          </InputGroupAddon>
          <Input type='password' name='password' placeholder='Senha' />
        </InputGroup>
        <InputGroup className='mb-3'>
          <InputGroupAddon addonType='prepend'>
            <InputGroupText>
              <i className='icon-doc' />
            </InputGroupText>
          </InputGroupAddon>
          <Input type='text' name='contract' placeholder='Contrato' />
        </InputGroup>
        <p className='text-danger' />
        <Row>
          <Col xs='6'>
            <Button type='submit' className='px-4'>
              Login
            </Button>
          </Col>
          <Col xs='6' className='text-right'>
            <Button color='link' className='px-0'>
              Contatar suporte
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Login
