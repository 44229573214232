import { lazily } from 'react-lazily'

const {
  Vendas,
  Esplanada,
  Extracao,
  Estoque,
  Gis,
  Dashboard,
  Relatorios,
} = lazily(() => import('./pages'))

// change also _nav.js
const routes = [
  { path: '/app/', exact: true, name: 'Home' },
  { path: '/app/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/app/gis', name: 'GIS', component: Gis },
  { path: '/app/vendas', name: 'Vendas', component: Vendas },
  { path: '/app/esplanadao', name: 'Esplanadão', component: Esplanada },
  { path: '/app/estoque', name: 'Estoque', component: Estoque },
  { path: '/app/extracao', name: 'Extração', component: Extracao },
  { path: '/app/relatorios', name: 'Relatórios', component: Relatorios },

  // { path: '/outro-link', exact: true, name: 'Outro', component: CadastroNota },
]
export default routes
