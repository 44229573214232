/* eslint-disable no-underscore-dangle */
import Axios from 'axios'
import { getConfig } from '../store/globals'
import { loadState } from '../store/localStorage'

const axios = Axios.create({})

export function api(token = null) {
  axios.defaults.baseURL = getConfig().BACKEND_URL
  axios.defaults.headers = {
    'x-auth-token': !token ? `${loadState()?.state?.user?.token}` : token,
  }
  return axios
}
