import { createReducer, createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
  successGetAbatidas: ['data'],
  errorGetAbatidas: ['error'],
  clearAbatidas: [''],
  startGetAbatidas: ['data'],
  successGetAutex: ['data'],
  errorGetAutex: ['error'],
  clearAutex: [''],
  startGetAutex: ['data'],
  successGetCortadaMato: ['data'],
  errorGetCortadaMato: ['error'],
  startGetCortadaMato: ['data'],
  successGetPatioArraste: ['data'],
  errorGetPatioArraste: ['error'],
  startGetPatioArraste: ['data'],
  successGetButtonEstoque: ['data'],
  errorGetButtonEstoque: ['error'],
  clearButtonEstoque: [''],
  startGetButtonEstoque: ['data'],
  successGetButtonVendas: ['data'],
  errorGetButtonVendas: ['error'],
  clearButtonVendas: [''],
  startGetButtonVendas: ['data'],
})

const initialState = {
  error: '',
  abatidas: [],
  estoque: [],
  vendas: [],
  autex: [],
  cortadaMato: '0',
}

function clearAutex(state) {
  return {
    ...state,
    autex: [],
  }
}

function successGetAutex(state, { data }) {
  return {
    ...state,
    autex: data,
  }
}

function errorGetAutex(state, { error }) {
  return {
    ...state,
    error,
  }
}

function successGetCortadaMato(state, { data }) {
  return {
    ...state,
    cortadaMato: data && data[0] && data[0].M3 ? data[0].M3.toFixed(3) : '',
  }
}

function errorGetCortadaMato(state, { error }) {
  return {
    ...state,
    error,
  }
}

function successGetPatioArraste(state, { data }) {
  return {
    ...state,
    patioArraste: data && data[0] && data[0].M3 ? data[0].M3.toFixed(3) : '',
  }
}

function errorGetPatioArraste(state, { error }) {
  return {
    ...state,
    error,
  }
}

function clearButtonVendas(state) {
  return {
    ...state,
    vendas: [],
  }
}

function successGetButtonVendas(state, { data }) {
  return {
    ...state,
    vendas: data,
  }
}

function errorGetButtonVendas(state, { error }) {
  return {
    ...state,
    error,
  }
}

function clearAbatidas(state) {
  return {
    ...state,
    abatidas: [],
  }
}

function successGetAbatidas(state, { data }) {
  return {
    ...state,
    abatidas: data,
  }
}

function errorGetAbatidas(state, { error }) {
  return {
    ...state,
    error,
  }
}

function clearButtonEstoque(state) {
  return {
    ...state,
    estoque: [],
  }
}

function successGetButtonEstoque(state, { data }) {
  return {
    ...state,
    estoque: data,
  }
}

function errorGetButtonEstoque(state, { error }) {
  return {
    ...state,
    error,
  }
}

const buttons = {
  [Types.SUCCESS_GET_ABATIDAS]: successGetAbatidas,
  [Types.ERROR_GET_ABATIDAS]: errorGetAbatidas,
  [Types.CLEAR_ABATIDAS]: clearAbatidas,
  [Types.SUCCESS_GET_AUTEX]: successGetAutex,
  [Types.ERROR_GET_AUTEX]: errorGetAutex,
  [Types.SUCCESS_GET_CORTADA_MATO]: successGetCortadaMato,
  [Types.ERROR_GET_CORTADA_MATO]: errorGetCortadaMato,
  [Types.SUCCESS_GET_PATIO_ARRASTE]: successGetPatioArraste,
  [Types.ERROR_GET_PATIO_ARRASTE]: errorGetPatioArraste,
  [Types.CLEAR_AUTEX]: clearAutex,
  [Types.SUCCESS_GET_BUTTON_ESTOQUE]: successGetButtonEstoque,
  [Types.ERROR_GET_BUTTON_ESTOQUE]: errorGetButtonEstoque,
  [Types.CLEAR_BUTTON_ESTOQUE]: clearButtonEstoque,
  [Types.SUCCESS_GET_BUTTON_VENDAS]: successGetButtonVendas,
  [Types.ERROR_GET_BUTTON_VENDAS]: errorGetButtonVendas,
  [Types.CLEAR_BUTTON_VENDAS]: clearButtonVendas,
}

export default createReducer(initialState, buttons)
