import { takeLeading, put, call } from 'redux-saga/effects'
import { getEsplanada as getEsplanadaRequest } from '../../services/esplanada'
import {
  Creators as esplanadaCreators,
  Types as esplanadaTypes,
} from '../ducks/esplanada'
import { Creators as notifyCreators } from '../ducks/notify'

function* GetEsplanada({ data }) {
  try {
    const response = yield call(getEsplanadaRequest, data)
    const { venda } = response.data.data[0]
    yield put(esplanadaCreators.successGetEsplanada(venda))
  } catch (error) {
    yield put(esplanadaCreators.errorGetEsplanada(error))
    yield put(
      notifyCreators.error(
        `Não foi possível encontrar dados de esplanada para o projeto`
      )
    )
  }
}

function* sagaEsplanada() {
  yield takeLeading(esplanadaTypes.START_GET_ESPLANADA, GetEsplanada)
}

export default sagaEsplanada
