/* eslint-disable no-underscore-dangle */
import { createReducer, createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
  startLogin: ['params'],
  startGetProjects: ['data', 'onSuccess', 'selected'],
  successLogin: ['data'],
  errorLogin: ['error'],
  setProject: ['data'],
  setCompany: ['data'],
  successGetCompanies: ['data'],
  errorGetCompanies: ['error'],
  successGetProjects: ['data'],
  errorGetProjects: ['error'],
  successAddRatingResponse: ['data'],
  errorAddRatingResponse: ['error'],
  clearUserData: [''],
  errorGetFiles: ['error'],
  successGetFiles: ['data'],
  startGetFiles: ['data'],
  errorGetFilesFromAWS: ['error'],
  successGetFilesFromAWS: ['data'],
  startGetFilesFromAWS: ['data'],
})

const initialState = {
  error: '',
  project: {
    selected: {
      name: '',
      id: '',
    },
    data: [],
  },
  files: {
    ids: [],
    data: [],
  },
  company: {
    selected: {
      name: '',
      id: '',
    },
    data: [],
  },
  user: {
    username: '',
    token: '',
    contract: {
      id: '',
      number: '',
    },
  },
}

function clearUserData() {
  return initialState
}

function successGetCompanies(state, { data }) {
  return {
    ...state,
    company: {
      ...state.company,
      data,
    },
  }
}

function successGetProjects(state, { data }) {
  return {
    ...state,
    project: {
      ...state.project,
      data,
    },
  }
}

function successLogin(state, { data }) {
  const { token, contract, username } = data
  return {
    ...state,
    user: {
      token,
      contract,
      username,
    },
  }
}

function setCompany(state, { data }) {
  return {
    ...state,
    company: {
      ...state.company,
      selected: {
        id: data.id,
        name: data.name,
      },
    },
  }
}

function setProject(state, { data }) {
  return {
    ...state,
    project: {
      ...state.project,
      selected: {
        id: data._id,
        name: data.name,
      },
    },
  }
}

function errorGetCompanies(state, { error }) {
  return {
    ...state,
    error,
  }
}

function errorGetProjects(state, { error }) {
  return {
    ...state,
    error,
  }
}

function errorLogin(state, { error }) {
  return {
    ...state,
    error,
  }
}

function successGetFiles(state, { data }) {
  return {
    ...state,
    files: {
      ...state.files,
      ids: data,
    },
  }
}

function errorGetFiles(state, { error }) {
  return {
    ...state,
    error,
  }
}

function successGetFilesFromAWS(state, { data }) {
  return {
    ...state,
    files: {
      ...state.files,
      data,
    },
  }
}

function errorGetFilesFromAWS(state, { error }) {
  return {
    ...state,
    error,
  }
}

const login = {
  [Types.SUCCESS_GET_COMPANIES]: successGetCompanies,
  [Types.SUCCESS_LOGIN]: successLogin,
  [Types.ERROR_GET_COMPANIES]: errorGetCompanies,
  [Types.SUCCESS_GET_PROJECTS]: successGetProjects,
  [Types.ERROR_GET_PROJECTS]: errorGetProjects,
  [Types.ERROR_LOGIN]: errorLogin,
  [Types.CLEAR_USER_DATA]: clearUserData,
  [Types.SET_COMPANY]: setCompany,
  [Types.SET_PROJECT]: setProject,
  [Types.SUCCESS_GET_FILES]: successGetFiles,
  [Types.ERROR_GET_FILES]: errorGetFiles,
  [Types.SUCCESS_GET_FILES_FROM_AWS]: successGetFilesFromAWS,
  [Types.ERROR_GET_FILES_FROM_AWS]: errorGetFilesFromAWS,
}

export default createReducer(initialState, login)
