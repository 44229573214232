/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import { useSelector, useDispatch } from 'react-redux'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'
import { Creators as qrcodeCreators } from '../../store/ducks/qrcode'
import * as S from './styles'
import Logo from '../../assets/img/logo.png'

const customMarker = new L.Icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
})

const QRCode = ({ toasty: Toast }) => {
  const dispatch = useDispatch()
  const [isMap, setIsMap] = useState()
  const { qrcode } = useSelector((state) => state.loader)
  const {
    tree: {
      coordX,
      coordY,
      posiX,
      posiY,
      arvoreNum,
      especie,
      altura,
      volume,
      comprimento,
      volumeFl,
      letraTora,
      autex,
      nrTora,
      idPlaqueta,
      seqPlaqueta,
      uca,
      UT,
      nome,
    },
  } = useSelector((state) => state.qrcode)
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  let idSeq
  let label
  let contract
  let ut
  let cor
  let faixa
  let idorigem
  let idorigemseq

  let isOk = false

  // https://web.absolutsistemas.com.br/qrcode?idplaqueta=30348&seqplaqueta=G&idorigem=144#&contract=10

  if (urlParams.get('idorigem') && urlParams.get('idplaqueta')) {
    idSeq = urlParams.get('idplaqueta')
    label = urlParams.get('seqplaqueta')
    contract = urlParams.get('contract')
    ut = urlParams.get('UT')
    idorigem = urlParams.get('idorigem')
    faixa = urlParams.get('faixa')
    idorigemseq = true
    isOk = true
  } else if (urlParams.get('idorigem')) {
    idSeq = urlParams.get('nrtora')
    label = urlParams.get('letratora')
    contract = urlParams.get('contract')
    ut = urlParams.get('UT')
    idorigem = urlParams.get('idorigem')
    faixa = urlParams.get('faixa')
    isOk = true
  }

  if (urlParams.get('faixa') && !isOk) {
    idSeq = urlParams.get('nrtora')
    label = urlParams.get('letratora')
    contract = urlParams.get('contract')
    ut = urlParams.get('UT')
    cor = urlParams.get('cor')
    faixa = urlParams.get('faixa')
  }

  if (urlParams.get('idplaqueta')) {
    idSeq = urlParams.get('idplaqueta')
    label = urlParams.get('seqplaqueta')
    contract = urlParams.get('contract')
    ut = urlParams.get('UT')
    cor = urlParams.get('cor')
    faixa = urlParams.get('faixa')
  }

  useEffect(() => {
    dispatch(
      qrcodeCreators.startGetTree(
        { idSeq, label, contract, ut, cor, faixa, idorigem, idorigemseq },
        () => {}
      )
    )
  }, [])

  const toggleMap = () => {
    setIsMap(true)
  }

  const isFloat = (value) => {
    if (
      typeof value === 'number' &&
      !Number.isNaN(value) &&
      !Number.isInteger(value)
    ) {
      return true
    }

    return false
  }

  return (
    <S.Container>
      <Toast />
      <LoadingOverlay active={!!qrcode.getTree} spinner />
      {!qrcode.getTree ? (
        isMap && isFloat(coordX) && isFloat(coordY) ? (
          <>
            <S.GoBack onClick={() => setIsMap(false)}>
              <svg
                width='132'
                height='128'
                viewBox='0 0 132 128'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g filter='url(#filter0_d_305_467)'>
                  <path
                    d='M46 48C46 44.6863 48.6863 42 52 42H80C83.3137 42 86 44.6863 86 48V76C86 79.3137 83.3137 82 80 82H52C48.6863 82 46 79.3137 46 76V48Z'
                    fill='#333333'
                  />
                </g>
                <path
                  d='M67.125 71.0999L58.7 62.6999C58.6 62.5999 58.5294 62.4916 58.488 62.3749C58.446 62.2582 58.425 62.1332 58.425 61.9999C58.425 61.8666 58.446 61.7416 58.488 61.6249C58.5294 61.5082 58.6 61.3999 58.7 61.2999L67.125 52.8749C67.3584 52.6416 67.65 52.5249 68 52.5249C68.35 52.5249 68.65 52.6499 68.9 52.8999C69.15 53.1499 69.275 53.4416 69.275 53.7749C69.275 54.1082 69.15 54.3999 68.9 54.6499L61.55 61.9999L68.9 69.3499C69.1334 69.5832 69.25 69.8706 69.25 70.2119C69.25 70.5539 69.125 70.8499 68.875 71.0999C68.625 71.3499 68.3334 71.4749 68 71.4749C67.6667 71.4749 67.375 71.3499 67.125 71.0999V71.0999Z'
                  fill='#FDB623'
                />
                <defs>
                  <filter
                    id='filter0_d_305_467'
                    x='0'
                    y='-4'
                    width='132'
                    height='132'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                  >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                      in='SourceAlpha'
                      type='matrix'
                      values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                      result='hardAlpha'
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation='23' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix
                      type='matrix'
                      values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0'
                    />
                    <feBlend
                      mode='normal'
                      in2='BackgroundImageFix'
                      result='effect1_dropShadow_305_467'
                    />
                    <feBlend
                      mode='normal'
                      in='SourceGraphic'
                      in2='effect1_dropShadow_305_467'
                      result='shape'
                    />
                  </filter>
                </defs>
              </svg>
            </S.GoBack>
            <MapContainer
              style={{
                height: '100vh',
                width: '100vw',
                position: 'fixed',
                left: '0',
                top: '0',
              }}
              center={[coordX, coordY]}
              zoom={10}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
              />
              <Marker position={[coordX, coordY]} icon={customMarker} />
            </MapContainer>
          </>
        ) : (
          <S.Card>
            <S.Header>
              <img alt='Absolut Sistemas Logo' src={Logo} />
            </S.Header>
            <S.Body>
              <S.Row>
                <S.Field col={33.33}>
                  <S.Label>Nr Árvore</S.Label>
                  <S.Box> {arvoreNum || 'N/A'} </S.Box>
                </S.Field>
                <S.Field col={33.33}>
                  <S.Label>Altura</S.Label>
                  <S.Box> {altura || 'N/A'} </S.Box>
                </S.Field>
                <S.Field last col={33.33}>
                  <S.Label>Volume</S.Label>
                  <S.Box>
                    {' '}
                    {(volume ? volume.toFixed(4) : false) || 'N/A'}{' '}
                  </S.Box>
                </S.Field>
              </S.Row>
              <S.Row>
                <S.Field col={33.33}>
                  <S.Label>UCA</S.Label>
                  <S.Box> {uca || 'N/A'} </S.Box>
                </S.Field>
                <S.Field last col={66.67}>
                  <S.Label>Espécie</S.Label>
                  <S.Box> {especie || 'N/A'} </S.Box>
                </S.Field>
              </S.Row>
              <S.Row>
                <S.Field col={50}>
                  <S.Label>Posição X</S.Label>
                  <S.Box> {posiX || 'N/A'} </S.Box>
                </S.Field>
                <S.Field last col={50}>
                  <S.Label>Posição Y</S.Label>
                  <S.Box> {posiY || 'N/A'} </S.Box>
                </S.Field>
              </S.Row>
              <S.Row>
                <S.Field col={50}>
                  <S.Label>Coordenada X</S.Label>
                  <S.Box> {coordX || 'N/A'} </S.Box>
                </S.Field>
                <S.Field last col={50}>
                  <S.Label>Coordenada Y</S.Label>
                  <S.Box> {coordY || 'N/A'} </S.Box>
                </S.Field>
              </S.Row>
              <S.Row>
                <S.Field col={100}>
                  <S.Label>Autex</S.Label>
                  <S.Box> {autex || 'N/A'} </S.Box>
                </S.Field>
              </S.Row>
              <S.Row>
                <S.Field col={50}>
                  <S.Label>Plaqueta</S.Label>
                  <S.Box>
                    {' '}
                    {`${idPlaqueta || 'N/A'} - ${seqPlaqueta || 'N/A'}`}{' '}
                  </S.Box>
                </S.Field>
                <S.Field last col={50}>
                  <S.Label>Nr Tora - Letra - UT</S.Label>
                  <S.Box>
                    {' '}
                    {`${nrTora || 'N/A'} - ${letraTora || 'N/A'} -  ${
                      UT || 'N/A'
                    }`}{' '}
                  </S.Box>
                </S.Field>
              </S.Row>
              <S.Row>
                <S.Field col={50}>
                  <S.Label>Comprimento</S.Label>
                  <S.Box>
                    {' '}
                    {(comprimento ? comprimento.toFixed(2) : false) ||
                      'N/A'}{' '}
                  </S.Box>
                </S.Field>
                <S.Field last col={50}>
                  <S.Label>Volume Fl</S.Label>
                  <S.Box> {volumeFl || 'N/A'} </S.Box>
                </S.Field>
              </S.Row>

              <S.Row>
                <S.Field col={100}>
                  <S.Label>Comprador</S.Label>
                  <S.Box>{nome || 'N/A'}</S.Box>
                </S.Field>
              </S.Row>
              <S.Row>
                <S.Field col={50}>
                  <S.Label>Estoque</S.Label>
                  <S.Box>{nome ? 'Vendida' : 'Em estoque'}</S.Box>
                </S.Field>
              </S.Row>
            </S.Body>
            <S.Footer>
              <S.Button onClick={toggleMap}>
                <svg
                  width='19'
                  height='17'
                  viewBox='0 0 19 17'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M18.6569 1.20184C18.8714 1.35125 19 1.59696 19 1.85926V13.0155C19 13.3475 18.7955 13.6431 18.4887 13.7593L12.947 15.8843C12.7755 15.9507 12.5875 15.954 12.416 15.8942L6.34983 13.8622L1.07205 15.8876C0.827951 15.9806 0.554167 15.9473 0.339757 15.7979C0.125347 15.6485 0 15.4028 0 15.1405V3.98426C0 3.65223 0.204514 3.35672 0.511285 3.24051L6.05295 1.11551C6.22448 1.0491 6.4125 1.04578 6.58403 1.10555L12.6502 3.13758L17.928 1.11219C18.172 1.01922 18.4458 1.05242 18.6602 1.20184H18.6569ZM1.58333 4.53211V13.985L5.54167 12.4677V3.01473L1.58333 4.53211ZM11.875 14.0348V4.55867L7.125 2.96492V12.4411L11.875 14.0348ZM13.4583 13.985L17.4167 12.4677V3.01473L13.4583 4.53211V13.985Z'
                    fill='#333333'
                  />
                </svg>
                <span>Ver no mapa</span>
              </S.Button>
            </S.Footer>
          </S.Card>
        )
      ) : (
        <></>
      )}
    </S.Container>
  )
}

export default QRCode
