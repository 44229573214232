/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { push } from 'connected-react-router'
import * as router from 'react-router-dom'
import { Container } from 'reactstrap'
import PropTypes from 'prop-types'

import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react'
import { useDispatch } from 'react-redux'
import { Creators as loginCreators } from '../../store/ducks/login'

// REMOVED IMPORTS

import navigation from '../../_nav'
// routes config
import routes from '../../routes'

const DefaultFooter = React.lazy(() => import('./DefaultFooter'))
const DefaultHeader = React.lazy(() => import('./DefaultHeader'))

const DefaultLayout = (props) => {
  const loading = () => (
    <div className='animated fadeIn pt-1 text-center'>Loading...</div>
  )
  const { location, toasty: Toast } = props
  const dispatch = useDispatch()

  const signOut = (e) => {
    e.preventDefault()
    dispatch(loginCreators.clearUserData())
    dispatch(push('/login'))
  }

  const { pathname } = location

  return (
    <div className='app'>
      {pathname !== '/app/gis' && (
        <AppHeader fixed>
          <Suspense fallback={loading()}>
            <DefaultHeader onLogout={(e) => signOut(e)} />
          </Suspense>
        </AppHeader>
      )}
      <div className='app-body'>
        <Toast />
        {pathname !== '/app/gis' && (
          <AppSidebar fixed display='lg'>
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
              <AppSidebarNav navConfig={navigation} router={router} />
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
        )}
        <main className='main'>
          {/* {pathname !== '/app/gis' && <AppBreadcrumb appRoutes={[]} router={router}/>} */}
          <Container fluid style={{ paddingTop: '20px' }}>
            <Suspense fallback={loading()}>
              <Switch>
                {routes.map((route) =>
                  route.component ? (
                    <Route
                      key={route.name}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={() => <route.component {...props} />}
                    />
                  ) : null
                )}
                <Redirect from='/app' to='/app/dashboard' />
              </Switch>
            </Suspense>
          </Container>
        </main>
      </div>
      {pathname !== '/app/gis' && (
        <AppFooter>
          <Suspense fallback={loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      )}
    </div>
  )
}

DefaultLayout.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

DefaultLayout.defaultProps = {
  history: {
    push: () => null,
  },
  location: {
    pathname: '',
  },
}

export default DefaultLayout
