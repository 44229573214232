import styled, { css } from 'styled-components'

const getContainerResponsive = (size) => {
  if (size === 'md' || size === 'lg') {
    return css``
  }

  return css`
    width: 100%;
  `
}
const getCardResponsive = (size) => {
  if (size === 'md' || size === 'lg') {
    return css``
  }

  return css`
    margin-top: 25px;
    margin-bottom: 25px;
  `
}

export const Container = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Poppins&display=swap');
  font-family: Poppins;
  background: rgba(51, 51, 51, 0.84);
  box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.11);
  width: 100vw;
  display: flex;
  min-height: 100vh;
  ${({ theme: { size } }) => getContainerResponsive(size)};
`

export const GoBack = styled.div``
export const Card = styled.div`
  background: rgba(59, 59, 59, 0.78);
  border-width: 2px 0px;
  border-style: solid;
  border-color: #fdb623;
  border-radius: 6px;
  max-width: 336px;
  width: 100%;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;

  ${({ theme: { size } }) => getCardResponsive(size)}
`
export const Header = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`
export const Body = styled.div`
  display: flex;
  flex-direction: column;
`
export const Footer = styled.div``
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  width: 100%;
  gap: 15px;
`
export const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ col }) => col}%;
`
export const Label = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #d9d9d9;
  margin-bottom: 8px;
`
export const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #fdb623;
  border-radius: 6px;
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #333333;
    margin-left: 10px;
  }
  width: fit-content;
  padding: 15px 18px;
  margin: 0 auto;
  cursor: pointer;
  margin-top: 20px;
`

export const Box = styled.div`
  background: rgba(51, 51, 51, 0.8);
  border: 0.5px solid #d9d9d9;
  border-radius: 6px;
  padding: 8px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: justify;

  color: #d9d9d9;
`
