import { takeLeading, put, call } from 'redux-saga/effects'
import { getPatioArraste as getPatioArrasteRequest } from '../../services/buttons'
import {
  Creators as buttonsCreators,
  Types as buttonsTypes,
} from '../ducks/buttons'
import { Creators as notifyCreators } from '../ducks/notify'

function* GetPatioArraste({ data }) {
  try {
    const response = yield call(getPatioArrasteRequest, data)
    const patioArraste = response.data.data
    yield put(buttonsCreators.successGetPatioArraste(patioArraste))
  } catch (error) {
    yield put(buttonsCreators.errorGetPatioArraste(error))
    yield put(
      notifyCreators.error(
        `Não foi possível encontrar dados de patioArraste para o projeto`
      )
    )
  }
}

function* sagaCortadaMato() {
  yield takeLeading(buttonsTypes.START_GET_PATIO_ARRASTE, GetPatioArraste)
}

export default sagaCortadaMato
