import { takeLeading, put, call } from 'redux-saga/effects'
import { getAbatidas as getAbatidasRequest } from '../../services/buttons'
import {
  Creators as buttonsCreators,
  Types as buttonsTypes,
} from '../ducks/buttons'

import { Creators as notifyCreators } from '../ducks/notify'

function* GetAbatidas({ data }) {
  try {
    const response = yield call(getAbatidasRequest, data)
    const abatidas = response.data.data
    yield put(buttonsCreators.successGetAbatidas(abatidas))
  } catch (error) {
    yield put(buttonsCreators.errorGetAbatidas(error))
    yield put(
      notifyCreators.error(
        `Não foi possível encontrar dados de abatidas para o projeto`
      )
    )
  }
}

function* sagaAbatidas() {
  yield takeLeading(buttonsTypes.START_GET_ABATIDAS, GetAbatidas)
}

export default sagaAbatidas
