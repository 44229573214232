import { createReducer, createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
  successGetEsplanada: ['data'],
  errorGetEsplanada: ['error'],
  clearEsplanada: [''],
  startGetEsplanada: ['data'],
})

const initialState = {
  error: '',
  esplanada: [],
}

function clearEsplanada() {
  return initialState
}

function successGetEsplanada(state, { data }) {
  return {
    ...state,
    esplanada: data,
  }
}

function errorGetEsplanada(state, { error }) {
  return {
    ...state,
    error,
  }
}

const esplanada = {
  [Types.SUCCESS_GET_ESPLANADA]: successGetEsplanada,
  [Types.ERROR_GET_ESPLANADA]: errorGetEsplanada,
  [Types.CLEAR_ESPLANADA]: clearEsplanada,
}

export default createReducer(initialState, esplanada)
