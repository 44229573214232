/* eslint-disable react/prop-types */
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
// import { ThemeProvider } from 'styled-components'

// import { renderRoutes } from 'react-router-config';
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import configureStore, { history } from './store'
import { loadState } from './store/localStorage'
// import getGridClass from './hooks/getGridClass'

import './App.scss'
import 'react-toastify/dist/ReactToastify.css'
import { isAuthenticated, isAuthenticatedAdmin } from './auth'
import { setConfig } from './store/globals'

import DefaultLayout from './containers/DefaultLayout'
import DefaultLayoutAdmin from './containers-admin/DefaultLayout'
import Login from './pages/Login'
import QRCode from './pages/QRCode'

import LoginAdmin from './views/Pages/Login'
import RegisterAdmin from './views/Pages/Register'
import Page404 from './views/Pages/Page404'
import Page500 from './views/Pages/Page500'

setConfig({
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
})
const PrivateRouteAdmin = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticatedAdmin() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/admin/login', state: { from: props.location } }}
        />
      )
    }
  />
)

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          // eslint-disable-next-line react/prop-types
          to={{ pathname: '/app/login' }}
        />
      )
    }
  />
)

const store = configureStore(loadState())

const toast = () => (
  <ToastContainer
    position='top-right'
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
)

// eslint-disable-next-line react/prop-types
const App = () => (
  // const { size } = getGridClass()
  // const theme = { size }
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route
          exact
          path='/'
          render={() => (
            <Redirect
              // eslint-disable-next-line react/prop-types
              to={{ pathname: '/app/login' }}
            />
          )}
        />
        <Route
          exact
          path='/login'
          render={() => (
            <Redirect
              // eslint-disable-next-line react/prop-types
              to={{ pathname: '/app/login' }}
            />
          )}
        />
        <Route
          exact
          path='/dashboard'
          render={() => (
            <Redirect
              // eslint-disable-next-line react/prop-types
              to={{ pathname: '/app/dashboard' }}
            />
          )}
        />
        <Route
          exact
          path='/app/login'
          name='Login Page'
          render={(props) => <Login toasty={() => toast()} {...props} />}
        />
        {/* <ThemeProvider theme={theme}> */}
        <Route
          exact
          path='/qrcode'
          name='QRCode Page'
          render={(props) => <QRCode toasty={() => toast()} {...props} />}
        />
        {/* </ThemeProvider> */}

        <PrivateRoute
          path='/app'
          component={(props) => (
            <DefaultLayout toasty={() => toast()} {...props} />
          )}
        />
        <Route
          exact
          path='/admin/login'
          name='Login Page'
          render={(props) => <LoginAdmin {...props} />}
        />
        <Route
          exact
          path='/admin/register'
          name='Register Page'
          render={(props) => <RegisterAdmin {...props} />}
        />
        <Route
          exact
          path='/admin/404'
          name='Page 404'
          render={(props) => <Page404 {...props} />}
        />
        <Route
          exact
          path='/admin/500'
          name='Page 500'
          render={(props) => <Page500 {...props} />}
        />
        <PrivateRouteAdmin
          path='/admin'
          component={(props) => <DefaultLayoutAdmin {...props} />}
        />
      </Switch>
    </ConnectedRouter>
  </Provider>
)

export default App
