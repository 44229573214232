import { takeLeading, put, call } from 'redux-saga/effects'
import { getButtonEstoque as getButtonEstoqueRequest } from '../../services/buttons'
import {
  Creators as buttonsCreators,
  Types as buttonsTypes,
} from '../ducks/buttons'

import { Creators as notifyCreators } from '../ducks/notify'

function* GetButtonEstoque({ data }) {
  try {
    const response = yield call(getButtonEstoqueRequest, data)
    const buttonEstoque = response.data.data
    if (!buttonEstoque.message) {
      yield put(buttonsCreators.successGetButtonEstoque(buttonEstoque))
    } else {
      yield put(buttonsCreators.errorGetButtonEstoque(buttonEstoque))
    }
  } catch (error) {
    yield put(buttonsCreators.errorGetButtonEstoque(error))
    yield put(
      notifyCreators.error(
        `Não foi possível encontrar dados de buttonEstoque para o projeto`
      )
    )
  }
}

function* sagaButtonEstoque() {
  yield takeLeading(buttonsTypes.START_GET_BUTTON_ESTOQUE, GetButtonEstoque)
}

export default sagaButtonEstoque
