import { api } from './api'

export const getQRCode = ({
  idSeq,
  label,
  contract,
  ut,
  cor,
  faixa,
  idorigem,
  idorigemseq,
}) => {
  if (idorigem) {
    return api().get(
      `/tree/${idSeq}&${label}&${ut}&${
        cor || '_'
      }&${faixa}&${contract}&${idorigem}${
        idorigemseq ? `&${idorigemseq}` : ''
      }/qrcode`
    )
  }
  if (faixa) {
    return api().get(
      `/tree/${idSeq}&${label}&${ut}&${cor}&${faixa}&${contract}/qrcode`
    )
  }

  return api().get(`/tree/${idSeq}&${label}&_&${cor}&_&${contract}/qrcode`)
}
