/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { takeLeading, put, call, all } from 'redux-saga/effects'
import axios from 'axios'
import * as CSV from 'csv-string'
import { processCsvData, processGeojson } from 'kepler.gl/processors'
import {
  login as loginRequest,
  getCompanies as getCompaniesRequest,
  getProjects as getProjectsRequest,
  getFiles as getFilesRequest,
} from '../../services/login'
import { Creators as loginCreators, Types as loginTypes } from '../ducks/login'
import { Creators as gisCreators } from '../ducks/gis'
import { Creators as notifyCreators } from '../ducks/notify'
import { LABELS } from '../../constants'

function* PostLogin(payload) {
  try {
    const response = yield call(loginRequest, payload)
    const { token } = response.data
    const { contract, username } = response.data.data
    const { params } = payload
    const dataObj = {
      token,
      contract: {
        id: contract,
        number: params.contract,
      },
      username,
    }
    yield put(loginCreators.successLogin(dataObj))
    try {
      const companiesResponse = yield call(getCompaniesRequest, {
        token,
        contract: {
          id: contract,
          number: params.contract,
        },
      })
      if (!companiesResponse.data.success) throw companiesResponse.data.message
      yield put(loginCreators.successGetCompanies(companiesResponse.data.data))
    } catch (error) {
      yield put(loginCreators.errorGetCompanies(error))
      yield put(
        notifyCreators.error(
          `Erro ao buscar as empresas relacionadas ao usuário ${username}`
        )
      )
    }
  } catch (error) {
    yield put(loginCreators.errorLogin(error))
    yield put(notifyCreators.error(`Usuário, senha ou contrato incorretos`))
  }
}

function* GetProjects({ data, onSuccess, selected }) {
  try {
    const projects = yield call(getProjectsRequest, data)
    yield put(loginCreators.successGetProjects(projects.data.data))
    yield put(
      loginCreators.startGetFiles({
        project: selected ? selected.id : projects.data.data[0]._id,
        token: data.token,
      })
    )
    if (onSuccess) {
      onSuccess(projects.data.data[0])
    }
  } catch (error) {
    yield put(loginCreators.errorGetProjects(error))
    yield put(notifyCreators.error(`Erro ao buscar os projetos para a empresa`))
  }
}

function* GetFiles({ data }) {
  try {
    const files = yield call(getFilesRequest, data)
    yield put(loginCreators.successGetFiles(files.data.data))
    yield put(loginCreators.startGetFilesFromAWS(files.data.data))
  } catch (error) {
    yield put(loginCreators.errorGetFiles(error))
    yield put(notifyCreators.error(`Erro ao buscar os arquivos deste projeto`))
  }
}
const removeZerosFromCSV = (data) =>
  data.map((row) => row.map((col) => (col === '0' ? '' : col)))

const getAWSData = (path) =>
  axios.get(path, {
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  })

const parseAWSData = (data, isCSV) => {
  // eslint-disable-next-line no-param-reassign
  data = isCSV ? CSV.stringify(removeZerosFromCSV(CSV.parse(data))) : data
  const initialCSVArray = isCSV ? processCsvData(data) : processGeojson(data)
  if (isCSV) {
    initialCSVArray.fields.forEach((field) => {
      // eslint-disable-next-line no-param-reassign
      field.name = LABELS[field.name]
    })
  }
  return initialCSVArray
}

function* GetFilesFromAWS({ data }) {
  try {
    const promises = yield all(data.map((file) => call(getAWSData, file.path)))
    let lastUpdated = null
    const allFilesData = []
    promises.forEach((file) => {
      const isCSV = file.config.url.indexOf('csv') !== -1
      const currentFile = parseAWSData(file.data, isCSV)
      const fileName = file.config.url.split('.amazonaws.com/')[1]
      const fileObj = {
        file: currentFile || null,
        fileName,
      }
      if (isCSV) {
        const dataObj = new Date(parseInt(fileName.split('-')[0], 10))
        lastUpdated = dataObj.toLocaleDateString('pt-BR', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
        lastUpdated = lastUpdated.charAt(0).toUpperCase() + lastUpdated.slice(1)
      }
      allFilesData.push(fileObj)
    })
    yield put(
      gisCreators.setMap({
        projectData: allFilesData,
        dataLastUpdate: lastUpdated,
      })
    )
    yield put(loginCreators.successGetFilesFromAWS(allFilesData))
  } catch (error) {
    yield put(loginCreators.errorGetFilesFromAWS(error))
    yield put(notifyCreators.error(`Erro ao baixar os arquivos do projeto`))
  }
}

function* SagaLogin() {
  yield takeLeading(loginTypes.START_LOGIN, PostLogin)
  yield takeLeading(loginTypes.START_GET_PROJECTS, GetProjects)
  yield takeLeading(loginTypes.START_GET_FILES, GetFiles)
  yield takeLeading(loginTypes.START_GET_FILES_FROM_AWS, GetFilesFromAWS)
}

export default SagaLogin
