import { createReducer, createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
  setMap: ['data'],
  clearMap: [''],
})

const initialState = {
  error: '',
  projectData: null,
  dataLastUpdate: '',
}

function clearMap() {
  return initialState
}

function setMap(state, { data }) {
  const { projectData, dataLastUpdate } = data
  return {
    ...state,
    projectData,
    dataLastUpdate,
  }
}

const gis = {
  [Types.SET_MAP]: setMap,
  [Types.CLEAR_MAP]: clearMap,
}

export default createReducer(initialState, gis)
