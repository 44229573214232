import { takeLeading, put, call } from 'redux-saga/effects'
import { getEstoque as getEstoqueRequest } from '../../services/estoque'
import {
  Creators as estoqueCreators,
  Types as estoqueTypes,
} from '../ducks/estoque'
import { Creators as notifyCreators } from '../ducks/notify'

function* GetEstoque({ data }) {
  try {
    const response = yield call(getEstoqueRequest, data)
    const estoque = response.data.data
    yield put(estoqueCreators.successGetEstoque(estoque))
  } catch (error) {
    yield put(estoqueCreators.errorGetEstoque(error))
    yield put(
      notifyCreators.error(
        `Não foi possível encontrar dados de estoque para o projeto`
      )
    )
  }
}

function* sagaEstoque() {
  yield takeLeading(estoqueTypes.START_GET_ESTOQUE, GetEstoque)
}

export default sagaEstoque
