import { takeLeading, put, call } from 'redux-saga/effects'
import { getExtracao as getExtracaoRequest } from '../../services/extracao'
import {
  Creators as extracaoCreators,
  Types as extracaoTypes,
} from '../ducks/extracao'
import { Creators as notifyCreators } from '../ducks/notify'

function* GetExtracao({ data }) {
  try {
    const response = yield call(getExtracaoRequest, data)
    const { venda } = response.data.data[0]
    yield put(extracaoCreators.successGetExtracao(venda))
  } catch (error) {
    yield put(extracaoCreators.errorGetExtracao(error))
    yield put(
      notifyCreators.error(
        `Não foi possível encontrar dados de extracao para o projeto`
      )
    )
  }
}

function* sagaExtracao() {
  yield takeLeading(extracaoTypes.START_GET_EXTRACAO, GetExtracao)
}

export default sagaExtracao
