import { takeLeading, put, call } from 'redux-saga/effects'
import { getVendas as getVendasRequest } from '../../services/vendas'
import {
  Creators as vendasCreators,
  Types as vendasTypes,
} from '../ducks/vendas'
import { Creators as notifyCreators } from '../ducks/notify'

function* GetVendas({ data }) {
  try {
    const response = yield call(getVendasRequest, data)
    const { venda } = response.data.data[0]
    yield put(vendasCreators.successGetVendas(venda))
  } catch (error) {
    yield put(vendasCreators.errorGetVendas(error))
    yield put(
      notifyCreators.error(
        `Não foi possível encontrar dados de vendas para o projeto`
      )
    )
  }
}

function* sagaVendas() {
  yield takeLeading(vendasTypes.START_GET_VENDAS, GetVendas)
}

export default sagaVendas
