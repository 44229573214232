import { takeLeading, put, call } from 'redux-saga/effects'
import { getRelatorios as getRelatoriosRequest } from '../../services/relatorios'
import {
  Creators as relatoriosCreators,
  Types as relatoriosTypes,
} from '../ducks/relatorios'
import { Creators as notifyCreators } from '../ducks/notify'

function* GetRelatorios({ data, onSuccess }) {
  try {
    const response = yield call(getRelatoriosRequest, data)
    const relatorios = response.data.data
    yield put(relatoriosCreators.successGetRelatorios(relatorios))
    onSuccess(relatorios)
  } catch (error) {
    yield put(relatoriosCreators.errorGetRelatorios(error))
    yield put(
      notifyCreators.error(
        `Não foi possível encontrar dados de relatorios para o projeto`
      )
    )
  }
}

function* sagaRelatorios() {
  yield takeLeading(relatoriosTypes.START_GET_RELATORIOS, GetRelatorios)
}

export default sagaRelatorios
