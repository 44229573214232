import { createReducer, createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
  successGetExtracao: ['data'],
  errorGetExtracao: ['error'],
  clearExtracao: [''],
  startGetExtracao: ['data'],
})

const initialState = {
  error: '',
  extracao: [],
}

function clearExtracao() {
  return initialState
}

function successGetExtracao(state, { data }) {
  return {
    ...state,
    extracao: data,
  }
}

function errorGetExtracao(state, { error }) {
  return {
    ...state,
    error,
  }
}

const extracao = {
  [Types.SUCCESS_GET_EXTRACAO]: successGetExtracao,
  [Types.ERROR_GET_EXTRACAO]: errorGetExtracao,
  [Types.CLEAR_EXTRACAO]: clearExtracao,
}

export default createReducer(initialState, extracao)
