import { all } from 'redux-saga/effects'
import sagaLogin from './sagaLogin'
import sagaEsplanada from './sagaEsplanada'
import sagaEstoque from './sagaEstoque'
import sagaExtracao from './sagaExtracao'
import sagaVendas from './sagaVendas'
import sagaAutex from './sagaAutex'
import sagaButtonVendas from './sagaButtonVendas'
import sagaButtonEstoque from './sagaButtonEstoque'
import sagaAbatidas from './sagaAbatidas'
import sagaLoader from './sagaLoader'
import sagaRelatorios from './sagaRelatorios'
import sagaQRCode from './sagaQRCode'
import sagaCortadaMato from './sagaCortadaMato'
import sagaPatioArraste from './sagaPatioArraste'

export default function* sagas() {
  yield all([
    sagaButtonEstoque(),
    sagaButtonVendas(),
    sagaLogin(),
    sagaLoader(),
    sagaAbatidas(),
    sagaEsplanada(),
    sagaEstoque(),
    sagaExtracao(),
    sagaVendas(),
    sagaAutex(),
    sagaRelatorios(),
    sagaQRCode(),
    sagaCortadaMato(),
    sagaPatioArraste(),
  ])
}
