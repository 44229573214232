import { createReducer, createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
  successGetVendas: ['data'],
  errorGetVendas: ['error'],
  clearVendas: [''],
  startGetVendas: ['data'],
})

const initialState = {
  error: '',
  vendas: [],
}

function clearVendas() {
  return initialState
}

function successGetVendas(state, { data }) {
  return {
    ...state,
    vendas: data,
  }
}

function errorGetVendas(state, { error }) {
  return {
    ...state,
    error,
  }
}

const vendas = {
  [Types.SUCCESS_GET_VENDAS]: successGetVendas,
  [Types.ERROR_GET_VENDAS]: errorGetVendas,
  [Types.CLEAR_VENDAS]: clearVendas,
}

export default createReducer(initialState, vendas)
