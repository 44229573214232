import { loadState } from './store/localStorage'

export const isAuthenticated = () => {
  const state = loadState()
  if (state?.login?.user?.token) {
    return true
  }
  return false
}

export const isAuthenticatedAdmin = () => {
  if (localStorage.getItem('loginToken')) {
    return true
  }
  return false
}
