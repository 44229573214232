import { createReducer, createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
  successGetTree: ['data'],
  errorGetTree: ['error'],
  startGetTree: ['data', 'onSuccess'],
})

const initialState = {
  error: '',
  tree: {
    coordX: null,
    coordY: null,
    posiX: null,
    posiY: null,
    arvoreNum: null,
    especie: null,
    altura: null,
    volume: null,
    comprimento: null,
    volumeFl: null,
    letraTora: null,
    autex: null,
    nrTora: null,
    uca: null,
    idPlaqueta: null,
    seqPlaqueta: null,
    UT: null,
    nome: null,
  },
}

function successGetTree(state, { data }) {
  console.log('DATA: ', data)
  return {
    ...state,
    tree: {
      coordX: data.data['0'].COORD_x || data.data['0'].LATITUDE,
      coordY: data.data['0'].COORD_Y || data.data['0'].LONGITUDE,
      posiX: data.data['0'].POSICAOX,
      posiY: data.data['0'].POSICAOY,
      arvoreNum: data.data['0'].ARVORENUM,
      especie: data.data['0'].ESPECIE,
      altura: data.data['0'].ALTURA,
      volume: data.data['0'].VOLUME,
      comprimento: data.data.COMP_FLORESTAL,
      volumeFl: data.data.VOLUME_FL,
      letraTora: data.data.LETRA_TORA.trim(),
      autex: data.data.AUTEX,
      nrTora: data.data.NR_TORA,
      uca: data.data['0'].UCA,
      idPlaqueta: data.data.ID_PLAQUETA,
      seqPlaqueta: data.data.SEQ_PLAQUETA,
      UT: data.data.UT,
      nome: data.data.NOME,
    },
  }
}

function errorGetTree(state, { error }) {
  return {
    ...state,
    error,
  }
}

const qrcode = {
  [Types.SUCCESS_GET_TREE]: successGetTree,
  [Types.ERROR_GET_TREE]: errorGetTree,
}

export default createReducer(initialState, qrcode)
