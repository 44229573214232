/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-constructor */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

class BreadCrumbAdmin extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const isDashboard = window.location.hash === '/admin/dashboard'
    const isContract = window.location.hash === '/admin/contract'
    const isProject = window.location.hash === '/admin/project'
    const isFiles = window.location.hash === '/admin/file'
    console.log(isDashboard)
    return (
      <nav className='breadcrumb'>
        {isDashboard ? (
          <div>
            <span className='breadcrumb-item active'>Dashboard</span>
          </div>
        ) : (
          <div />
        )}
        {isContract ? (
          <div>
            <a className='breadcrumb-item' href='/admin'>
              Dashboard
            </a>
            <span className='breadcrumb-item active'>Contrato</span>
          </div>
        ) : (
          <div />
        )}
        {isProject ? (
          <div>
            <a className='breadcrumb-item' href='/admin'>
              Dashboard
            </a>
            <a className='breadcrumb-item' href='/admin/contract'>
              Contrato
            </a>
            <span className='breadcrumb-item active'>Projetos</span>
          </div>
        ) : (
          <div />
        )}
        {isFiles ? (
          <div>
            <a className='breadcrumb-item' href='/admin'>
              Dashboard
            </a>
            <a className='breadcrumb-item' href='/admin/contract'>
              Contrato
            </a>
            <a className='breadcrumb-item' href='/admin/project'>
              Projetos
            </a>
            <span className='breadcrumb-item active'>Arquivos</span>
          </div>
        ) : (
          <div />
        )}
      </nav>
    )
  }
}

export default BreadCrumbAdmin
