import { createReducer, createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
  startLoading: ['data'],
  stopLoading: ['data'],
  stopLoadingFull: ['data'],
})

const initialState = {
  qrcode: {
    getTree: 0,
  },
  login: {
    loginUser: 0,
    getCompanies: 0,
    getProjects: 0,
  },
  esplanada: {
    getEsplanada: 0,
  },
  estoque: {
    getEstoque: 0,
  },
  vendas: {
    getVendas: 0,
  },
  extracao: {
    getExtracao: 0,
  },
  dashboard: {
    getDashboard: 0,
  },
  relatorios: {
    relatoriosDownload: 0,
  },
}

/**
 *  {
 *    object: "promocode",
 *    method: "list"
 *  }
 */
function startLoading(state, { data }) {
  return {
    ...state,
    [data.object]: {
      ...state[data.object],
      [data.method]: state[data.object][data.method] + 1,
    },
  }
}

function stopLoading(state, { data }) {
  return {
    ...state,
    [data.object]: {
      ...state[data.object],
      [data.method]: state[data.object][data.method]
        ? state[data.object][data.method] - 1
        : state[data.object][data.method],
    },
  }
}

function stopLoadingFull(state, { data }) {
  return {
    ...state,
    [data]: initialState[data],
  }
}

const loader = {
  [Types.START_LOADING]: startLoading,
  [Types.STOP_LOADING]: stopLoading,
  [Types.STOP_LOADING_FULL]: stopLoadingFull,
}

export default createReducer(initialState, loader)
