import { takeLeading, put, call } from 'redux-saga/effects'
import { getAutex as getAutexRequest } from '../../services/buttons'
import {
  Creators as buttonsCreators,
  Types as buttonsTypes,
} from '../ducks/buttons'
import { Creators as notifyCreators } from '../ducks/notify'

function* GetAutex({ data }) {
  try {
    const response = yield call(getAutexRequest, data)
    const autex = response.data.data
    yield put(buttonsCreators.successGetAutex(autex))
  } catch (error) {
    yield put(buttonsCreators.errorGetAutex(error))
    yield put(
      notifyCreators.error(
        `Não foi possível encontrar dados de autex para o projeto`
      )
    )
  }
}

function* sagaAutex() {
  yield takeLeading(buttonsTypes.START_GET_AUTEX, GetAutex)
}

export default sagaAutex
