export const LABELS = {
  UID: 'ID',
  ESPECIE: 'Espécie',
  ARVORENUM: 'Número da Árvore',
  UPA: 'UPA',
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
  NOMECIENTIFICO: 'Nome Científico',
  UT: 'UT',
  TIPO: 'Tipo',
  DATA_CORTE: 'Data do Corte',
  DATA: 'Data',
  ESPECI_ESP: 'ESPECI_ESP',
  ESPL_M3: 'ESPL_M3',
  QTD_TORAS: 'Quantidade de Toras',
  ESPL_FL_M3: 'ESPL_FL_M3',
  MOTIVO: 'Motivo',
  PATIO: 'Pátio',
  DATA_PLAN_ARRASTE: 'DATA_PLAN_ARRASTE',
  MOTOSERRISTA: 'Motoserrista',
  VOLUMESCASCA: 'Volume de Casca',
  FAIXA: 'Faixa',
  VOLUME: 'Volume',
  VENDA_FL_M3: 'VENDA_FL_M3',
}

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export const AUTH_TOKENS = {
  MAPBOX_TOKEN: process.env.REACT_APP_MapboxAccessToken,
}

export const TOAST_OPTIONS = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}
