/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */

import React, { Component } from 'react'
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap'

class Page404 extends Component {
  render() {
    return (
      <div className='app flex-row align-items-center'>
        <Container>
          <Row className='justify-content-center'>
            <Col md='6'>
              <div className='clearfix'>
                <h1 className='float-left display-3 mr-4'>404</h1>
                <h4 className='pt-3'>Oops! You're lost.</h4>
                <p className='text-muted float-left'>
                  The page you are looking for was not found.
                </p>
              </div>
              <InputGroup className='input-prepend'>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>
                    <i className='fa fa-search' />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  size='16'
                  type='text'
                  placeholder='What are you looking for?'
                />
                <InputGroupAddon addonType='append'>
                  <Button color='info'>Search</Button>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Page404
